import { Grid, Typography } from "@mui/material";
import React, { FC, useContext, useState } from "react";
import carImage from "../../../../assets/illustrations/not-sure-whats-wrong-car.svg";
import { LinkButton } from "../../../library-components/Buttons/Buttons";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../context/GlobalContext";
import { TechnicalAssistanceDialog } from "../../../library-components/Dialogs/TechincalAssistance/TechnicalAssistanceDialog";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    padding: 0,
    width: "50vw",
  },
  span: {},
});

const Assistance: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { dispatch } = useContext(GlobalContext);

  const [openDialog, setOpenDialog] = useState(false);

  const header = () => {
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem 0 0 0",
          }}
        >
          <Typography
            fontSize="14px"
            fontFamily={"Poppins, SemiBold"}
            fontWeight={1000}
          >
            {t("notSureWhatsWrongTitle")}
          </Typography>
        </div>
      </div>
    );
  };

  const content = () => {
    return (
      <div>
        <Typography
          textAlign={"center"}
          fontFamily={"Poppins, Medium"}
          fontSize={12}
        >
          {t("notSureWhatsWrongDescription")}
          <span>
            <LinkButton
              onClick={() => {
                setOpenDialog(true);
              }}
              style={{
                color: "black",
                cursor: "pointer",
                textDecorationColor: "black",
                fontWeight: "bold",
              }}
            >
              {t("formular")}
            </LinkButton>
          </span>
          {t("notSureWhatsWrongDescriptionContinued")}
        </Typography>
      </div>
    );
  };

  const actions = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // padding: "0px",
        }}
      >
        <Typography
          fontFamily={"Poppins, Normal"}
          fontSize={"14px"}
          dangerouslySetInnerHTML={{
            __html: t("notSureWhatsWrongPhone"),
          }}
        ></Typography>
      </div>
    );
  };

  return (
    <Grid className={classes.root} item container>
      <div
        style={{
          width: "37vw",
          border: "1px solid",
          borderColor: "rgba(112, 112, 112, 0.1)",
        }}
      ></div>
      <Grid
        item
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "3% 0 1% 0",
        }}
      >
        {header()}
      </Grid>
      <Grid
        item
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 0 2% 0",
          width: "32vw",
        }}
      >
        {content()}
      </Grid>
      <Grid
        item
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 0 2% 0",
        }}
      >
        {actions()}
      </Grid>
      <TechnicalAssistanceDialog
        open={openDialog}
        close={(value) => {
          if (value === true) {
            dispatch({
              type: "CLEAR_ZIP_CODE_AND_LICENCE_FORM",
              payload: undefined,
            });
          }
          setOpenDialog(!openDialog);
        }}
        flag={"S"}
      />
    </Grid>
  );
};

export default Assistance;
