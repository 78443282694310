import { Grid, Typography } from "@mui/material";
import React, { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../context/GlobalContext";
import { LinkButton } from "../../library-components/Buttons/Buttons";
import { TechnicalAssistanceDialog } from "../../library-components/Dialogs/TechincalAssistance/TechnicalAssistanceDialog";

const NotSureWhatsWrong: FC = () => {
  const { t } = useTranslation();
  const [openDialogTechnical, setOpenDialogTechnical] = useState(false);
  const { state, dispatch } = useContext(GlobalContext);

  return (
    <Grid>
      <Grid
        style={{
          width: "93%",
          margin: "0 auto",
        }}
      >
        <Grid
          style={{
            border: "1px solid #4BC0E1",
            padding: "1.5%",
          }}
        >
          <div>
            <Typography
              fontSize="14px"
              fontFamily={"Poppins, SemiBold"}
              fontWeight={1000}
            >
              {t("notSureWhatsWrongTitle")}
            </Typography>
          </div>
          <div>
            <Typography fontFamily={"Poppins, Medium"} fontSize={14}>
              {t("notSureWhatsWrongDescription")}
              <span>
                <LinkButton
                  onClick={() => {
                    setOpenDialogTechnical(true);
                  }}
                  style={{
                    color: "black",
                    cursor: "pointer",
                    textDecorationColor: "black",
                    fontWeight: "bold",
                  }}
                >
                  {t("formular")}
                </LinkButton>
              </span>
              {t("notSureWhatsWrongDescriptionContinued")}
              <span
                style={{
                  fontFamily: "Poppins, Normal",
                  fontSize: "14px",
                }}
                dangerouslySetInnerHTML={{
                  __html: t("notSureWhatsWrongPhone"),
                }}
              ></span>
            </Typography>
          </div>
          <TechnicalAssistanceDialog
            open={openDialogTechnical}
            close={(value) => {
              if (value === true) {
                dispatch({
                  type: "CLEAR_ZIP_CODE_AND_LICENCE_FORM",
                  payload: undefined,
                });
              }
              setOpenDialogTechnical(!openDialogTechnical);
            }}
            flag={"S"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotSureWhatsWrong;
