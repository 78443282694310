import React, { FC } from "react";
import * as Material from "@mui/material";
import * as MuiIcons from "@mui/icons-material";
import { LinkButtonProps, SimpleButtonProps } from "./Types/ButtonTypes";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import { isMobile } from "react-device-detect";

/**
 * @memberof Buttons
 * @description Default simple button style
 * @default
 */
const defaultStyleSimpleButton = {
  backgroundColor: "#4BC0E1",
  color: "black",
  cursor: "pointer",
};

const useStylesButton = makeStyles({
  root: {
    backgroundColor: "#4BC0E1",
    color: "black",
    "& .MuiButton-root": {
      backgroundColor: "#4BC0E1",
      color: "white",
    },
    maxWidth: isMobile ? "" : 155,
    maxHeight: isMobile ? "" : 47,
  },
  "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root": {
    backgroundColor: "#4BC0E1",
    color: "black",
  },
});

/**
 * @memberof Buttons
 * @param props className, children, style, variant
 * @description The class is for the button, variant are predefined,
 * you can use one of them: "text" | "outlined" | "contained" | undefined, the children you pass will be rendered, and an inline style option
 * @returns Mui button
 */
export const SimpleButton: FC<SimpleButtonProps> = (props) => {
  const classes = useStylesButton();
  return (
    <LoadingButton
      id={props?.buttonId || ""}
      loading={props.loading}
      disabled={props.disabled}
      classes={{ root: classes.root }}
      style={props.style || defaultStyleSimpleButton}
      className={props?.classes}
      variant={props.variant}
      onClick={props.onClick}
      role={props.loading ? "progressbar" : "button"}
      loadingIndicator={
        <CircularProgress style={{ color: "white" }} size={32} />
      }
      focusRipple={props.focusRipple}
      type={props.type}
    >
      {props.loading === true ? "" : <>{props.children}</>}
    </LoadingButton>
  );
};

/**
 * @memberof Buttons
 * @description Default style for LinkButton, you can change it here
 * @default
 */
const useStylesLink = makeStyles({
  root: {
    color: "#353535",
    marginRight: "6%",
    cursor: "pointer",
    fontFamily: "Poppins",
  },
});

/**
 * @memberof Buttons
 * @param props style or it useses it's default styles, href, and the children (text or icons)
 * @description You just pass style which is optional, href(link or relative path (URI)) and the children you want to be rendered
 * @returns Mui Link, with the children passed
 */
export const LinkButton: FC<LinkButtonProps> = (props) => {
  const classes = useStylesLink();

  return (
    <Material.Link
      component={props.disabled ? "button" : "a"}
      disabled={props.disabled || false}
      classes={{ root: classes.root }}
      className={props?.classes}
      style={props.style}
      href={props.href}
      onClick={props.onClick}
    >
      {props.children}
    </Material.Link>
  );
};
