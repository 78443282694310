import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  useTheme,
  Typography,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, FC, useContext, useEffect, useState } from "react";

import { useDialogStylesReturningCustomer } from "./Styles/DialogStyle";
import { DialogProps } from "./Types/DialogTypes";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GlobalContext } from "../../../context/GlobalContext";
import { computeService } from "../../../API/graphql-queries/Queries";
import { ComputeServiceDataResponse } from "../../../interfaces-and-unionTypes/Interfaces";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useLazyQuery } from "react-apollo";
import Checkbox from "@mui/material/Checkbox";
import Unchecked from "../../../assets/illustrations/checkboxUnchecked.svg";
import Checked from "../../../assets/illustrations/checkboxChecked.svg";
import ServiceLoaderDialog from "./ServiceLoaderDialog";
import { useHistory } from "react-router-dom";
import InputField from "../InputFields/InputFields";

const RepairsServiceDialog: FC<DialogProps> = (props) => {
  const classes = useDialogStylesReturningCustomer();
  const { t } = useTranslation();
  const { state, dispatch } = useContext(GlobalContext);
  const history = useHistory();
  const [andetText, setAndetText] = useState(state?.andetComment)
  const theme = useTheme();
  const [error, setError] = useState("");
  const [serviceId, setServiceId] = useState("")
  const [theSub, setTheSub] = useState()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { open, close, content } = props;

  const closeDialog = (data: any) => {
    if (loading) return;
    close(basket);
  };
  const handleClose = () => {
    if (andetText !== "") {
      setLoading(true);
      checkChecked(theSub);
      computedService({
        variables: {
          is_selected: 1,
          service_id: serviceId,
          ticket_id: state.ticket?.node.ticket_id || "",
          milage: "0",
          tyre_data: null,
        },
      });
      // setLoading(false);
      return
    }
    if (loading) return;

    close(basket);
  };
  const [basket, setBasket] = useState(state.basketData?.service_details || []);
  const [loading, setLoading] = useState(false);

  const [computedService, result] = useLazyQuery<
    Partial<ComputeServiceDataResponse>
  >(computeService, {
    onCompleted: (data) => {
      dispatch({ type: "ADD_TO_BASKET", payload: data.computeService?.node });
      setBasket(data.computeService?.node?.service_details);
      setLoading(false);
      // setShow(false);
      // setShowDropDowns(false);
    },
    fetchPolicy: "no-cache",
  });



  useEffect(() => {
    const foundServiceDetail = content?.service_details?.find(
      (serviceDetail: any) => serviceDetail?.service_dsc === "Andet"
    );
    if (foundServiceDetail) {
      setTheSub(foundServiceDetail)
      setServiceId(foundServiceDetail?.service_id)
    }
    console.log(foundServiceDetail)
  }, [content])

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.computeService?.node === null) {
        alert(result?.data?.computeService?.message);
      }
      if (result?.data?.computeService?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  useEffect(() => {
    if (basket.find((el: any) => el.sa_id === state.serviceId)) {
      dispatch({
        type: "REMOVE_SERVICE_FROM_BASKET",
        payload: "",
      });
      const removed = basket.filter((el: any) => el.sa_id !== state.serviceId);
      setBasket(removed);
    }
  }, [state.serviceId, state.basketData]);

  const handleAddClick = (serviceId: string, checked: number) => {
    computedService({
      variables: {
        is_selected: checked,
        service_id: serviceId,
        ticket_id: state.ticket?.node.ticket_id || "",
        milage: "0",
        tyre_data: null,
      },
    });
  };

  const checkChecked = (sub: any) => {
    if (state.queryParams?.service_ids?.includes(sub?.service_id)) {
      return true;
    }
    return !!basket.find((el: any) => el.sa_id === sub?.service_id);
  };
  return (
    <Dialog
      open={open}
      className={classes.root}
      onClose={closeDialog}
      fullWidth={fullScreen}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "center",
          WebkitJustifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          WebkitAlignContent: "center",
          WebkitAlignItems: "center",
        }}
      >
        <Typography
          style={{
            textAlign: "center",
          }}
        >
          {content?.subcategory_dsc}
        </Typography>
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30px",
          fontFamily: "Poppins",
        }}
      >
        {/* !loading */}
        {content?.service_details?.map((sub: any) => {
          return (
            <div key={sub.service_dsc} style={{ width: "100%" }}>
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        color: "#4BC0E1",
                        "&.Mui-checked": {
                          color: "#4BC0E1",
                          "&.Mui-checked": {
                            color: "#4BC0E1",
                          },
                        },
                      }}
                      icon={<img src={Unchecked} />}
                      checkedIcon={<img src={Checked} />}
                      defaultChecked={checkChecked(sub)}
                      onChange={(e: any) => {
                        setLoading(true);
                        handleAddClick(
                          sub.service_id,
                          e.target.checked ? 1 : 0
                        );
                      }}
                    />
                  }
                  label={sub.service_dsc}
                />{" "}
              </Typography>

            </div>
          );
        })}
      </DialogContent>
      {/* <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "10vh",
          fontFamily: "Poppins",
        }}
      >
        {
          <LoadingButton
            style={{
              width: 155,
              height: 47,
              background: "#4BC0E1",
              color: "black",
              font: "22px",
              fontWeight: 600,
              textTransform: "capitalize",
            }}
            onClick={handleClose}
            role={"progressbar"}
            loading={loading}
            loadingIndicator={
              <CircularProgress style={{ color: "white" }} size={32} />
            }
          >
            {!loading && t("continueButtonLabel")}
          </LoadingButton>
        }
      </DialogActions> */}
      <ServiceLoaderDialog
        open={loading}
        close={() => {
          return;
        }}
      />
    </Dialog>
  );
};

export default RepairsServiceDialog;
