import { makeStyles } from "@mui/styles";
import { isMobile } from "react-device-detect";

export const useStylesTimeslot = makeStyles({
  selectedBtn: {
    backgroundColor: "#4bc0e1 !important",
    color: "white !important",
  },

  paperRoot: {
    margin: isMobile ? "20px 0px" : "20px",
    width: " 90%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    flexGrow: 1,
    left: "0px",
  },
});
