import React from "react";
import { makeStyles } from "@mui/styles";
import { isMobile } from "react-device-detect";

export const useDialogStyleCampaignCode = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    WebkitJustifyContent: "center",
    WebkitAlignContent: "center",
    width: "100vw",
    height: "100vh",

    "& .MuiPaper-root": {
      width: "42vw",
    },
  },
  rootMobile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    WebkitJustifyContent: "center",
    WebkitAlignContent: "center",
    width: "100vw",
    height: "100vh",

    "& .MuiPaper-root": {
      width: "100vw",
    },

    "& .MuiFormControl-root": {
      width: "60vw",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    WebkitJustifyContent: "center",
    alignItems: "center",
    WebkitAlignItems: "center",
  },
  content: {
    width: "100vw",
    height: "100%",
    display: "flex",
  },
  actions: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    WebkitJustifyContent: "center",
    WebkitAlignContent: "center",
  },
  inputAdornment: {
    color: "black",
    borderRight: "1px solid black",
  },
  "& makeStyles-root-58": {
    width: "100%",
  },
});

export const useInfoDialogStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    WebkitJustifyContent: "center",
    WebkitAlignContent: "center",
    width: "100vw",
    height: "100vh",

    "& .MuiPaper-root": {
      width: !isMobile ? "42vw" : "100vw",
    },
  },
});

export const useDialogStylesTechnicalAssistanceForm = makeStyles({
  root: {
    display: "flex",
    fontFamily: "Poppins",
    justifyContent: "center",
    alignItems: "center",
    WebkitJustifyContent: "center",
    WebkitAlignContent: "center",
    width: "100vw",
    height: "100%",
  },
  title: {
    background: "#4BC0E1",
    display: "flex",
    justifyContent: "center",
    WebkitJustifyContent: "center",
    alignItems: "center",
    WebkitAlignItems: "center",
    width: "18rem",
  },
  content: {
    width: "100vw",
    height: "100%",
    display: "flex",
  },
  actions: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    WebkitJustifyContent: "center",
    WebkitAlignContent: "center",
  },
  inputAdornment: {
    color: "black",
    borderRight: "1px solid black",
  },
  inputDiv: {
    padding: "13px",
    display: "flex",
    justifyContent: "space-around",
  },
});

export const useDialogStylesReturningCustomer = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    WebkitJustifyContent: "center",
    WebkitAlignContent: "center",
    width: "100vw",
    height: "100vh",

    "& .MuiPaper-root": {
      width: "100vw",
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    WebkitJustifyContent: "center",
    alignItems: "center",
    WebkitAlignItems: "center",
  },
  content: {
    width: "100vw",
    height: "100%",
    display: "flex",
  },
  actions: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    WebkitJustifyContent: "center",
    WebkitAlignContent: "center",
  },
  inputAdornment: {
    color: "black",
    borderRight: "1px solid black",
  },
});
