import React, { FC, useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { GlobalContext } from "../../../context/GlobalContext";
import { useStylesTimeslot } from "./TimeslotStyles";
import da from "date-fns/locale/da";
import { useStylesDatepicker } from "./DatepickerStyle";

interface TimeslotHeaderInterface {
  getPreviousWeek: () => void;
  getNextWeek: () => void;
  headerDay: any;
  fixedDate: boolean;
  calendarDays: number;
}

const TimeslotHeader: FC<TimeslotHeaderInterface> = ({
  getPreviousWeek,
  getNextWeek,
  headerDay,
  fixedDate,
  calendarDays,
}) => {
  const classesDatepicker = useStylesDatepicker();
  const goToPreviousWeek = () => {
    getPreviousWeek();
  };

  const goToNextWeek = () => {
    getNextWeek();
  };
  const todayDate: Date = new Date(
    new Date().getTime() + 3 * 24 * 60 * 60 * 1000
  );
  const [value, setValue] = useState<Date | null>(todayDate);
  const { state, dispatch } = useContext(GlobalContext);
  const classes = useStylesTimeslot();

  const [openPopover, setOpenPopover] = useState(false);

  return (
    <Grid
      container
      spacing={3}
      style={{
        width: "100%",
        marginLeft: "0px",
      }}
    >
      <Grid
        item
        xs
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "0",
        }}
      >
        <IconButton aria-label="previous" onClick={() => goToPreviousWeek()}>
          <ChevronLeftIcon />
        </IconButton>
      </Grid>
      <Button
        onClick={() => setOpenPopover(!openPopover)}
        style={{ display: "block" }}
      >
        <Grid
          item
          xs={6}
          style={{
            width: "100%",
            maxWidth: "100%",
          }}
        >
          <Typography
            style={{
              color: "#4BC0E1",
            }}
            fontSize={14}
            fontFamily={"Poppins. Poppins-Regular"}
            marginBottom="0px"
          >
            <span style={{ textTransform: "capitalize" }}>
              {headerDay.toLocaleString("da", { month: "long" })}{" "}
            </span>
            {headerDay.toLocaleString("default", { year: "numeric" })}
          </Typography>
        </Grid>
      </Button>
      <Grid
        item
        xs
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "0",
        }}
      >
        <IconButton aria-label="next" onClick={() => goToNextWeek()}>
          <ChevronRightIcon />
        </IconButton>
      </Grid>
      <Dialog
        open={openPopover}
        onClose={() => setOpenPopover(false)}
        fullWidth
      >
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={da}>
            <StaticDatePicker
              className={classesDatepicker.root}
              label="Custom input"
              value={value}
              minDate={todayDate}
              maxDate={
                new Date(
                  new Date().getTime() + calendarDays * 24 * 60 * 60 * 1000
                )
              }
              disableHighlightToday={true}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              onAccept={(value) => {
                dispatch({
                  type: "SELECTED_DATE",
                  payload: value !== null ? value : todayDate,
                });
                setOpenPopover(false);
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <input ref={inputRef} {...inputProps} />
                  {InputProps?.endAdornment}
                </Box>
              )}
              displayStaticWrapperAs={"desktop"}
            />
          </LocalizationProvider>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default TimeslotHeader;
