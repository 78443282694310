import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
    pallete: {
      borderColor: string;
      color: string;
      inputBorderColor: string;
      backgroundColor?: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
    pallete?: {
      borderColor?: string;
      color?: string;
      inputBorderColor?: string;
      backgroundColor?: string;
    };
  }
}

let theme = createTheme({
  typography: {
    fontFamily: ["Poppins"].join(","),
    button: {
      textTransform: "capitalize",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: "#4BC0E1",
          color: "black",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          maxWidth: "100%",
        }
      }
    }
  },
  shape: {},
  palette: {
    primary: {
      main: "#4BC0E1",
    },
    secondary: {
      main: "#4caf50",
    },
  },
});

theme = responsiveFontSizes(theme);

theme = createTheme(theme, {
  palette: {
    primary: {
      main: theme.palette.primary.main,
    },
  },
});

export { theme };
