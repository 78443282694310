/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid,  Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { LinkButton } from "../../library-components/Buttons/Buttons";
import { GlobalContext } from "../../../context/GlobalContext";
import { computeService } from "../../../API/graphql-queries/Queries";
import { ComputeServiceDataResponse } from "../../../interfaces-and-unionTypes/Interfaces";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import InfoDialog from "../../library-components/Dialogs/InfoDialog/InfoDialog";
import { NetworkStatus } from "apollo-boost";
import ProgressCarLoading from "../ProgressBar/ProgressCarLoading";
import TrustBox from "../../TrustBox/TrustBox";
import ContinueBackButtonsBasketMobile from "./ContinueBackButtonsBasketMobile";
import ServiceLoaderDialog from "../../library-components/Dialogs/ServiceLoaderDialog";
import { useStylesMobileBasketContent } from "../Styles/SummaryStyles";
import { splitUserInfo } from "../../../Helpers/stringHelpers";
import Basket2 from "../basket2/Basket2";
type BasketStylePropType = {
  isCheckout: boolean;
};

const BasketMobileContent: React.FC = () => {
  const props: BasketStylePropType = {
    isCheckout: window.location.pathname.includes("checkout"),
  };
  const { state, dispatch } = useContext(GlobalContext);

  const heavyCampaigns = ["fleggaardleasing0"]
  const { t } = useTranslation();

  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const query = useLocation();

  const basket2 = Basket2({});

  const classes = useStylesMobileBasketContent(props);
  const matchesMobile = useMediaQuery("(max-width: 350px)");

  const [basketItems, setBasketItems] = useState(
    state.basketData?.service_details || []
  );

  const [openDialogMin, setOpenDialogMin] = useState(false);

  const [computedService, result] = useLazyQuery<
    Partial<ComputeServiceDataResponse>
  >(computeService, {
    onCompleted: (data) => {
      dispatch({ type: "ADD_TO_BASKET", payload: data.computeService?.node });
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.computeService?.node === null) {
        alert(result?.data?.computeService?.message);
      }
      if (result?.data?.computeService?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  useEffect(() => {
    setBasketItems(state.basketData?.service_details);
  }, [state.basketData?.service_details]);

  const handleRemoveClick = (serviceId?: string) => {
    computedService({
      variables: {
        is_selected: 0,
        service_id: serviceId,
        ticket_id: state.ticket?.node.ticket_id || "",
        milage: "0",
        tyre_data: null,
      },
    });
    dispatch({
      type: "REMOVE_FROM_BASKET",
      payload: { serviceId, ...result?.data?.computeService?.node },
    });

    dispatch({
      type: "REMOVE_SERVICE_FROM_BASKET",
      payload: serviceId,
    });
  };

  const dialogParagraphs = [
    { 0: t("whatsThisDialogFirstParagraph") },
    { 1: t("whatsThisDialogSecondParagraph") },
    { 2: t("whatsThisDialogThirdParagraph") },
  ];

  return (
    <Grid item container className={classes.root} md={12}>
      {!window.location.pathname.includes("checkout") ? (
        <div className={classes.trustBox}>
          <TrustBox />
        </div>
      ) : null}
      {state.basketData && state.basketData?.service_details?.length >= 1 ? (
        <Grid container className={classes.wrapper}>
          {
            <div>
              {state.basketData !== null || state.basketData !== undefined ? (
                <div>
                  <div>
                    {!heavyCampaigns.includes(state.basketData?.campaign_code) ? <>
                      {window.location.pathname.includes("checkout") ? (
                        <div
                          className={`${classes.infoWrapperPrice} ${classes.priceInfo}`}
                        >
                          <span>
                            <Typography className={classes.priceInfoText}>
                              {t("header3")}
                            </Typography>
                          </span>
                          <span>
                            <Typography className={classes.priceInfoText}>
                              {state.basketData?.total_price?.trim() !== undefined
                                ? state.basketData?.total_price + " DKK"
                                : "0 DKK"}
                            </Typography>
                          </span>
                        </div>
                      ) : (
                        <div
                          className={`${classes.infoWrapper} ${classes.priceInfo}`}
                        >
                          <Typography className={classes.priceInfoText}>
                            {state.basketData?.total_price?.trim() !== undefined
                              ? state.basketData?.total_price + " DKK"
                              : "0 DKK"}
                          </Typography>
                        </div>
                      )}
                      </>
                     : (
                      <>
                      <div
                        className={`${classes.infoWrapperPrice} ${classes.priceInfo}`}
                      >
                        <span>
                          <Typography className={classes.priceInfoText}>
                            Fleggaard Leasing
                          </Typography>
                        </span>
                      </div>
                      </>
                    )}
                    <div className={classes.infoWrapper}>
                      <Typography color={"#353535"}>
                        {state.ticket?.node.make +
                          " - " +
                          state.ticket?.node.model +
                          " - " +
                          state.ticket?.node.year +
                          " - " +
                          state.ticket?.node.motortype}
                      </Typography>
                    </div>
                    <div className={classes.infoWrapper}>
                      {state?.basketData?.location_text ? (
                        <Typography className={classes.userInfoText}>
                          {splitUserInfo(state?.basketData?.location_text)}
                        </Typography>
                      ) : (
                        <Typography className={classes.userInfoText}>
                          {state.ticket?.node?.zip_code +
                            " - " +
                            state.ticket?.node.city +
                            " - " +
                            state.ticket?.node.country}
                        </Typography>
                      )}
                    </div>
                    <div
                      className={`${classes.infoWrapper} ${classes.editLinkWrapper}`}
                    >
                      <LinkButton
                        classes={classes.editLink}

                        onClick={() => history.push(`/?clear=1`)}
                      >
                        {t("editLink")}
                      </LinkButton>
                    </div>
                  </div>
                  {result.networkStatus !== NetworkStatus.loading ? (
                    <>
                    {basket2}
                    { state.basketData?.total_price?.trim() !==
                    undefined &&
                    state.basketData?.fee_amount && !state.returningCustomer.node.fleet_manager_for?.id &&
                      <p className="basket-vat-text">
                        { t("additionalFeesContactDetails") }
                      </p>
                    }
                    </>
                  ) : (
                    <Grid
                      item
                      container
                      md={12}
                      className={classes.progressCarLoading}
                    >
                      <ProgressCarLoading />
                    </Grid>
                  )}
                </div>
              ) : (
                <></>
              )}
              {state.basketData &&
                state.basketData?.service_details &&
                state.basketData?.service_details.length >= 1 &&
                parseInt(state.basketData?.total_price?.replace(".", "")) < 698 &&
                state.basketData?.root_missing !== 1 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "6vh",
                    paddingTop: "3%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                      height: "6vh",
                      paddingLeft: "3%",
                      background: "#e3edbb",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        fontSize={12}
                        fontWeight={600}
                        fontFamily={"Poppins, SemiBold"}
                      >
                        {t("minPriceDialogTitle")}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        paddingRight: "3%",
                      }}
                    >
                      <LinkButton
                        style={{
                          color: "#3265F3",
                          textDecorationColor: "#3265F3",
                        }}
                        onClick={() => setOpenDialogMin(!openDialogMin)}
                      >
                        <Typography fontSize={12}>
                          {t("whatsThisLink")}
                        </Typography>
                      </LinkButton>
                    </div>
                  </div>
                </div>
              ) : null}
              <ContinueBackButtonsBasketMobile matchesMobile={matchesMobile} />
              {!heavyCampaigns.includes(state.basketData?.campaign_code) && <>
                {state.basketData?.campaign_code ? (
                  <div>
                    {state.basketData?.total_discount !== "0.00" ? (
                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <Grid item md={12} fontFamily={"Poppins"} fontSize={11.5}>
                          <Typography fontFamily={"Poppins"} fontSize={11.5}>
                            {t("youAppliedCode")}{" "}
                            <span style={{ fontFamily: "Poppins-SemiBold" }}>
                              {state.basketData?.campaign_code}
                            </span>
                            {state.basketData?.total_discount?.length &&
                              state.basketData?.service_details?.length ? (
                              <span>
                                <span style={{ fontSize: "11.5" }}>
                                  {t("withDiscount")}
                                </span>
                                <span
                                  style={{
                                    fontSize: "11.5",
                                    fontFamily: "Poppins-SemiBold",
                                  }}
                                >
                                  {state.basketData?.total_discount + " DKK. "}
                                </span>
                                <span style={{ fontSize: "11.5" }}>
                                  {t("discountExplanation")}
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                          </Typography>
                        </Grid>
                      </div>
                    ) : (
                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <Grid item md={12} fontFamily={"Poppins"} fontSize={11.5}>
                          <Typography fontFamily={"Poppins"} fontSize={11.5}>
                            {t("campaignCode")}
                            {": "}
                            <span style={{ fontFamily: "Poppins-SemiBold" }}>
                              {state.basketData?.campaign_code}
                            </span>

                            <span>
                              <span style={{ fontSize: "11.5" }}>
                                {t("withDiscountPriceZero")}
                              </span>
                              <span
                                style={{
                                  fontSize: "11.5",
                                  fontFamily: "Poppins-SemiBold",
                                }}
                              >
                                {state.basketData?.total_discount + " DKK"}
                              </span>
                              <span style={{ fontSize: "11.5" }}>
                                {t("discountExplanationPriceZero")}
                              </span>
                            </span>
                          </Typography>
                        </Grid>
                      </div>
                    )}
                  </div>
                ) : null}</>}
            </div>
          }
          <InfoDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            title={t("whatsThisDialogTitle")}
            content={
              <>
                <div className={classes.whatsThisDialog}>
                  {dialogParagraphs.map((item: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        paddingTop: index === 0 ? "3%" : "5%",
                      }}
                    >
                      <Typography className={classes.whatsThisDialogText}>
                        {item[index]}
                      </Typography>
                    </div>
                  ))}
                </div>
              </>
            }
          />
          <InfoDialog
            open={openDialogMin}
            onClose={() => setOpenDialogMin(false)}
            title={t("minPriceDialogTitle")}
            content={
              <>
                <div className={classes.whatsThisDialog}>
                  <div className={classes.whatsThisDialogPadding}>
                    <Typography className={classes.whatsThisDialogText}>
                      {`Minimumskøb på ${parseInt(state.basketData?.min_price?.replace(".", ""))} DKK for online booking er ikke opnået. Fortsæt venligst din booking og vi kontakter dig indenfor 24 timer på hverdage.`}
                    </Typography>
                  </div>
                </div>
              </>
            }
          />
        </Grid>
      ) : (
        <div>
          <Typography>No items</Typography>
        </div>
      )}
      <ServiceLoaderDialog
        open={result.loading}
        close={() => {
          return;
        }}
      />
    </Grid>
  );
};

export default BasketMobileContent;
