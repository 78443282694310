import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isMobile } from "react-device-detect";
import { ButtonsProps } from "../Types/interfaces";
type BasketStylePropType = {
  isCheckout: boolean;
};
export const useStylesDesktopSummary = makeStyles<Theme, BasketStylePropType>({
  root: (props) => {
    return {
      display: "flex !important",
      justifyContent: "center !important",
      alignItems: "center !important",
      padding: !props.isCheckout ? "3.060% 0 !important" : "1% 0 !important",
      margin: "0 !important",
      width: !props.isCheckout ? "27vw !important" : "100vw !important",
      height: "100% !important",
    };
  },
  position: (props) => {
    return {
      float: !props.isCheckout
        ? ("right !important" as "right")
        : ("none !important" as "none"),
      width: !props.isCheckout ? "100% !important" : ("!important" as ""),
      height: !props.isCheckout ? "100% !important" : ("!important" as ""),
      marginLeft: !props.isCheckout ? "10% !important" : ("!important" as ""),
    };
  },
  wrapper: (props) => {
    return {
      position: !props.isCheckout
        ? ("fixed !important" as "fixed")
        : ("relative !important" as "relative"),
      overflowY: !props.isCheckout
        ? ("scroll !important" as "scroll")
        : ("visible !important" as "visible"),
      height: !props.isCheckout ? "70vh !important" : ("!important" as ""),
    };
  },
  reviews: {
    width: "100% !important",
    display: "flex !important",
    justifyContent: "center !important",
    marginTop: "20px !important",
    marginBottom: "0.5vh !important",
  },
  border: {
    padding: "2% 0px !important",
    borderBottom: "1px solid #0000001A !important",
  },
  basketLinks: {
    backgroundColor: "#ffffff",
    padding: "2vh 1vw",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    width: "27vw",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "35px 1fr",
    padding: "0.5vh 0vh",
  },
  thumbsUpImg: {
    width: "22px",
    height: "22px",
  },
  marginTop: {
    marginTop: "20px !important",
  },
  quoteText: {
    textDecoration: "none !important",
    color: "#4BC0E1 !important",
    fontWeight: "bold !important",
  },
  dialogCloseButton: {
    textTransform: "capitalize",
    backgroundColor: "#4BC0E1",
    color: "black",
    cursor: "pointer",
    width: "119px",
    height: "47px",
  },
  dialogCloseButtonText: {
    fontSize: "18px !important",
    fontWeight: "bold !important",
  },
  dialogCloseButtonWrapper: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    padding: "2% 0px 5% 0px !important",
  },
  dialogCloseButtonWrapperr: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    padding: "2% 0px 5% 0px !important",
  },
  iframe: {
    width: "80vw",
    height: "80vw",
  },
});

export const useStylesBasketContent = makeStyles<Theme, BasketStylePropType>({
  header: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "flex-start !important",
    fontWeight: "bold !important",
    paddingBottom: "2%",
    marginLeft: !isMobile ? "-1%" : "",
  },
  headerPrice: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "space-between !important",
    fontWeight: "bold !important",
    paddingBottom: "2%",
    marginLeft: !isMobile ? "-1%" : "",
  },
  price: {
    color: "black !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    width: "100% !important",
  },
  fees: {
    color: "black !important",
    fontWeight: "normal !important",
    fontSize: "9px !important",
  },
  userInfo: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    fontWeight: "bold",
    marginLeft: "-1.3%",
  },
  userInfoText: {
    color: "#353535 !important",
  },
  link: {
    padding: "2% 0px 3% 0px",
  },
  linkText: {
    fontWeight: "normal !important",
    color: "#3265F3 !important",
    textDecorationColor: "#3265F3 !important",
    fontSize: "12px !important",
  },
  basketBorder: {
    padding: "5% 0px !important",
    borderBottom: "1px solid #0000001A !important",
  },
  basketShadow: {
    boxShadow: "0 10px 10px -10px rgba(0,0,0,0.3) !important",
  },
  basketItemsRoot: (props) => {
    return {
      display: "flex !important",
      justifyContent: "flex-start !important",
      alignItems: !props.isCheckout
        ? "center !important"
        : "flex-start !important",
      paddingBottom: !props.isCheckout ? "0 !important" : "2% !important",
      flexDirection: "column !important" as "column",
      marginLeft: !isMobile ? "-4.3% !important" : "",
      paddingTop: "15px",
    };
  },
  basketItemsWrapper: (props) => {
    return {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "25vw",
      paddingLeft: !props.isCheckout ? "0% !important" : ("!important" as ""),
    };
  },
  basketItemWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  cancelWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: 12,
  },
  cancelIcon: {
    fontSize: 24,
    color: "black",
  },
  itemDescription: (props) => {
    return {
      width: !props.isCheckout ? "17vw !important" : "100% !important",
    };
  },
  itemDescriptionText: {
    fontSize: "12px !important",
    fontWeight: "bold !important",
  },
  itemPrice: (props) => {
    return {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: !isMobile
        ? !props.isCheckout
          ? "50% !important"
          : ("!important" as "")
        : "55%",
    };
  },
  missingItem: (props) => {
    return {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: !isMobile
        ? !props.isCheckout
          ? "25vw !important"
          : "100% !important"
        : "100%",
      height: !isMobile ? "5vh" : "100%",
    };
  },
  missingItemDescription: (props) => {
    return {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      paddingLeft: !isMobile ? (!props.isCheckout ? "3%" : "0%") : "3%",
    };
  },
  missingItemDescriptionText: {
    fontSize: "9.5px !important",
    fontWeight: "bold !important",
    fontFamily: "Poppins, SemiBold !important",
  },
  missingItemLink: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  missingItemLinkColor: {
    color: "#3265F3 !important",
    textDecorationColor: "#3265F3 !important",
  },
  missingItemLinkText: {
    fontSize: "10px !important",
  },
  progressCarLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  topBorderWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  topBorder: {
    borderTop: "1px solid #0000001A",
    width: "25vw",
  },
  whatsThisDialog: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  whatsThisDialogText: {
    fontSize: "14px !important",
    fontWeight: "regular !important",
    textAlign: "left !important" as "left",
  },
  continueButtonRoot: (props) => {
    return {
      paddingTop: !props.isCheckout ? "20px" : "",
    };
  },
  continueButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "2%",
  },
  continueButton: {
    background: "#67b39b !important",
    color: "white !important",
    textTransform: "none !important" as "none",
    width: "16vw !important",
    maxWidth: "16vw !important",
    height: "40px !important",
    fontWeight: "bold !important",
  },
});

export const useStylesMobileBasket = makeStyles({
  root: {
    position: "fixed",
    bottom: 0,
    background: "white",
    width: "100%",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.4)",
  },
  wrapper: {
    width: "100%",
  },
  itemsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    fontWeight: "bold",
    padding: "2%",
  },
  priceText: {
    color: "black !important",
    fontWeight: "bold !important",
    fontSize: "21px !important",
    width: "50% !important",
    paddingLeft: "8% !important",
  },
  headerTitle :{
    color: "black !important",
    fontWeight: "bold !important",
    fontSize: "21px !important",
  },
  missingItem: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    fontWeight: "bold",
    padding: "2% 0px 3% 0px",
    width: "69%",
    paddingRight: "7%",
  },
  link: {
    fontWeight: "normal !important",
    color: "rgb(247 44 44 / 1.64) !important",
    textDecorationColor: "rgb(247 44 44 / 1.64) !important",
    fontSize: "11.5px",
  },
  whatsThisDialog: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  whatsThisDialogText: {
    fontSize: "14px !important",
    fontWeight: "regular !important",
    textAlign: "left !important" as "left",
  },
  whatsThisDialogPadding: {
    paddingTop: "3%",
  },
});

export const useStylesMobileBasketContent = makeStyles<
  Theme,
  BasketStylePropType
>({
  root: (props) => {
    return {
      display: "flex !important",
      justifyContent: "center !important",
      alignItems: "center !important",
      marginTop: !props.isCheckout ? ("!important" as "") : "15px !important",
    };
  },
  trustBox: {
    marginBottom: "20px",
  },
  wrapper: {
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3) !important",
    width: "90% !important",
    padding: "7%",
  },
  infoWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    fontWeight: "bold !important",
  },
  infoWrapperPrice: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "space-between !important",
    fontWeight: "bold !important",
  },
  priceInfo: {
    paddingBottom: "2%",
  },
  priceInfoText: {
    color: "black !important",
    fontWeight: "bold !important",
    fontSize: "24px !important",
    width: "100% !important",
  },
  additionalFees: {
    color: "black !important",
    fontWeight: "normal !important",
    fontSize: "9px !important",
  },
  editLinkWrapper: {
    padding: "2% 0px 3% 0px",
  },
  editLink: {
    fontWeight: "normal !important",
    color: "#3265F3 !important",
    textDecorationColor: "#3265F3 !important",
    fontSize: "12px !important",
  },
  itemsRoot: (props) => {
    return {
      display: "flex !important",
      justifyContent: "flex-start !important",
      alignItems: !props.isCheckout
        ? "center !important"
        : "flex-start !important",
      paddingBottom: !props.isCheckout ? "0% !important" : "2% !important",
      flexDirection: "column !important" as "column",
    };
  },
  itemsWrapper: {
    alignItems: "center",
    width: "100%",
  },
  itemWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  cancelWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: 12,
  },
  cancelIcon: {
    fontSize: 24,
    color: "black",
  },
  itemDescriptionText: {
    fontSize: "12px !important",
    fontWeight: "bold !important",
  },
  priceWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "55%",
  },
  missingItemWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  missingItemDescription: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingLeft: "3%",
  },
  missingItemDescriptionText: {
    fontSize: "10px !important",
    fontWeight: "bold !important",
    fontFamily: "Poppins, SemiBold !important",
  },
  missingItemLink: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  missingItemLinkColor: {
    color: "#3265F3 !important",
    textDecorationColor: "#3265F3 !important",
  },
  missingItemLinkText: {
    fontSize: "10px !important",
  },
  progressCarLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  whatsThisDialog: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  whatsThisDialogText: {
    fontSize: "14px !important",
    fontWeight: "regular !important",
    textAlign: "left !important" as "left",
  },
  whatsThisDialogPadding: {
    paddingTop: "3%",
  },
});

export const useStylesMobileBasketButtons = makeStyles<Theme, ButtonsProps>({
  continueBackRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    paddingTop: "3%",
  },
  continueBackWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  continueBackPosition: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "4%",
  },
  backButton: (props) => {
    return {
      background: "#C3C3C3 !important",
      color: "black !important",
      textTransform: "capitalize !important" as "capitalize",
      width: "32vw !important",
      height: "47px !important",
      fontWeight: "bold !important",
      fontSize: "18px !important",
      marginRight: props.matchesMobile ? "25px !important" : "10px !important",
    };
  },
  continueButton: {
    background: "#67b39b !important",
    color: "white !important",
    textTransform: "none !important" as "none",
    width: "34vw !important",
    height: "47px !important",
    // marginLeft: "4% !important",
    fontWeight: "bold !important",
    fontSize: "18px !important",
  },
});

export const useStylesMobileBasketFloatingContinueButton = makeStyles({
  floatingContinueButtonRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column!important" as "column",
    width: "100%",
  },
  floatingContinueButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column!important" as "column",
  },
  floatingContinueButtonPosition: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "2%",
    flexDirection: "column!important" as "column",
  },
  floatingContinueButton: {
    background: "#67b39b !important",
    color: "white !important",
    textTransform: "none !important" as "none",
    width: "56vw !important",
    height: "47px !important",
    fontWeight: "bold !important",
    fontSize: "18px !important",
    flexDirection: "column!important" as "column",
  },
});
