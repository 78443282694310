/* eslint-disable @typescript-eslint/no-explicit-any */
import { Badge, Grid, IconButton, Link, Typography } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import ProgressTrackMobile from "./ProgressTrackMobile";
import { GlobalContext } from "../../../context/GlobalContext";
import { omnicarLogo, useStylesMobile } from "./NavbarStyle";
import Basket from "../../../assets/illustrations/shopping-cart.svg";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import Repairs from "../../../assets/illustrations/Repairs.svg";
import ServicingAndMOT from "../../../assets/illustrations/Servicing&MOT.svg";
import GlasOgRuder from "../../../assets/illustrations/Glass.svg";
import Tyres from "../../../assets/illustrations/Tyres.svg";
import PrePurchaseInspections from "../../../assets/illustrations/PrePurchaseInspections.svg";
import RepairsBlue from "../../../assets/illustrations/RepairBlue.svg";
import TyresBlue from "../../../assets/illustrations/TyreBlue.svg";
import GlassBlue from "../../../assets/illustrations/GlassBlue.svg";
import PrePurchaseInspectionBlue from "../../../assets/illustrations/PrePurchaseInspectionBlue.svg";
import ServicingAndMOTBlue from "../../../assets/illustrations/ServicingAndMOTBlue.svg";
import { useTranslation } from "react-i18next";
import chevronLeft from "../../../assets/illustrations/chevron-left.svg";
import { LinkButton } from "../Buttons/Buttons";

const NavbarMobile: FC = () => {
  const { state } = useContext(GlobalContext);

  const history = useHistory();

  const query = useLocation();

  const [value, setValue] = useState(0);

  const [currLoc, setCurrLoc] = useState(window.location.pathname || "");

  const classes = useStylesMobile();

  const { t } = useTranslation();

  const handleChange = (event: any, newValue: any) => {
    switch (newValue) {
      case 1:
        history.push(`/services/repairs${query?.search && ("/" + query.search)}`);
        break;
      case 2:
        history.push(`/services/tyres${query?.search && ("/" + query.search)}`);
        break;
      case 3:
        history.push(`/services/servicing${query?.search && ("/" + query.search)}`);
        break;
      case 4:
        history.push(`/services/inspections${query?.search && ("/" + query.search)}`);
        break;
      case 5:
        history.push(`/services/glass${query?.search && ("/" + query.search)}`);
        break;
    }
    setCurrLoc(window.location.pathname);
  };

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setCurrLoc(location.pathname);
    });

    // Cleanup by unsubscribing the listener when the component unmounts
    return () => unlisten();
  }, [history]);

  useEffect(() => {
    const currentLocation = window.location.pathname;
    if (currentLocation.includes("repairs")) {
      setValue(1);
    } else if (currentLocation.includes("tyres")) {
      setValue(2);
    } else if (currentLocation.includes("servicing")) {
      setValue(3);
    } else if (currentLocation.includes("inspections")) {
      setValue(4);
    } else if (currentLocation.includes("glass")) {
      setValue(5);
    } else {
      setValue(0);
      setCurrLoc(window.location.pathname);
    }
    setCurrLoc(window.location.pathname);
  }, []);

  useEffect(() => {
    const currentLocation = window.location.pathname;
    if (currentLocation.includes("repairs")) {
      setValue(1);
    } else if (currentLocation.includes("tyres")) {
      setValue(2);
    } else if (currentLocation.includes("servicing")) {
      setValue(3);
    } else if (currentLocation.includes("inspections")) {
      setValue(4);
    } else if (currentLocation.includes("glass")) {
      setValue(5);
    } else {
      setValue(0);
      setCurrLoc(window.location.pathname);
    }
    setCurrLoc(window.location.pathname);
  }, [currLoc]);

  const tabs = [
    {
      id: 1,
      title: t("repairs"),
      image: value !== 1 ? Repairs : RepairsBlue,
      color: value !== 1 ? "#353535" : "#4BC0E1",
      borderBottom: value === 1 ? "4px solid #4BC0E1" : "",
    },
    {
      id: 2,
      title: t("tyres"),
      image: value !== 2 ? Tyres : TyresBlue,
      color: value !== 2 ? "#353535" : "#4BC0E1",
      borderBottom: value === 2 ? "4px solid #4BC0E1" : "",
    },
    {
      id: 3,
      title: t("servicingAndMOT"),
      image: value !== 3 ? ServicingAndMOT : ServicingAndMOTBlue,
      color: value !== 3 ? "#353535" : "#4BC0E1",
      borderBottom: value === 3 ? "4px solid #4BC0E1" : "",
    },

    {
      id: 4,
      title: t("prePurchaseInspections"),
      image: value !== 4 ? PrePurchaseInspections : PrePurchaseInspectionBlue,
      color: value !== 4 ? "#353535" : "#4BC0E1",
      borderBottom: value === 4 ? "4px solid #4BC0E1" : "",
    },
    {
      id: 5,
      title: t("glasOgRuder"),
      image: value !== 5 ? GlasOgRuder : GlassBlue,
      color: value !== 5 ? "#353535" : "#4BC0E1",
      borderBottom: value === 5 ? "4px solid #4BC0E1" : "",
    },
  ];

  const setIconBlue = (id: number, action: string) => {
    if (action.trim() === "click") {
      setValue(id);
    } else {
      setValue(0);
    }
  };
  const getTotalItemsInBasket = (): number => {
    return state.basketData && state.basketData?.service_details
      ? state.basketData?.service_details?.length
      : 0;
  };

  return (
    <Grid item container className={classes.root}>
      <Grid item container md={3} className={classes.wrapper}>
        <div>
          {!currLoc.includes("services") &&
            !currLoc.includes("summary") &&
            !currLoc.includes("assistance") &&
            !currLoc.includes("returning") &&
            !currLoc.includes("basket") ? (
            <a href="https://omnicarservice.dk/" className={classes.link}>
              <img src={omnicarLogo} className={classes.logo} />
            </a>
          ) : (
            <IconButton
              onClick={() => {
                history.goBack();
              }}
              disabled={window.location.pathname.includes("summary")}
            >
              <img src={chevronLeft} className={classes.goBackImage} />
            </IconButton>
          )}
        </div>
        <Grid item container className={classes.trackerWrapper}>
          <ProgressTrackMobile />
        </Grid>
        <div className={classes.helpAndBasketWrapper}>
          <div>
            <LinkButton
              onClick={() => {
                history.push("/assistance");
              }}
              disabled={window.location.pathname.includes("summary")}
            >
              <Typography
                fontSize={10}
                fontWeight={"bold"}
                fontFamily={"Poppins, Bold"}
              >
                {t("navbarHelp")}
              </Typography>
            </LinkButton>
          </div>
          {!window.location.pathname.includes("summary") ? (
            <IconButton
              onClick={() => {
                history.push(`/basket${query.search && query.search}`);
              }}
              disabled={window.location.pathname.includes("summary")}
            >
              <Badge
                badgeContent={getTotalItemsInBasket()}
                classes={{
                  badge: classes.customBadge,
                  anchorOriginTopLeftCircular:
                    classes.anchorOriginTopLeftCircular,
                }}
              >
                <img src={Basket} className={classes.basketImage} />
              </Badge>
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                history.push(`/basket${query.search && query.search}`);
              }}
              disabled={window.location.pathname.includes("summary")}
            >
              <img src={Basket} className={classes.basketImage} />
            </IconButton>
          )}
        </div>
      </Grid>
      {value !== 0 ? (
        <Grid item container md={12} className={classes.servicesNavigation}>
          {tabs.map((item) => (
            <div
              key={item.id}
              style={{
                borderBottom: item.borderBottom,
                width: "15%",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                key={item.id}
                onClick={(e: any) => {
                  setIconBlue(item.id, "click");
                  handleChange(e, item.id);
                }}
                className={classes.servicesIconButton}
              >
                <div className={classes.servicesImageWrapper}>
                  <div>
                    <img src={item.image} className={classes.servicesImage} />
                  </div>
                  <div>
                    <Typography
                      fontFamily={"Poppins, Regular"}
                      fontSize={8}
                      color={item.color}
                    >
                      {item.title}
                    </Typography>
                  </div>
                </div>
              </IconButton>
            </div>
          ))}{" "}
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default withRouter(NavbarMobile);
