import { Typography } from "@mui/material";
import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../context/GlobalContext";
import { useStylesDesktopHeader } from "../Style/HeaderStyle";

const Header: FC<{ isExternal: boolean }> = (
  { isExternal }
) => {
  const { t } = useTranslation();

  const { state } = useContext(GlobalContext);
  const classes = useStylesDesktopHeader();


  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {!isExternal && (
          <Typography
            variant="h5"
            component="div"
            fontFamily={"Poppins"}
            fontWeight={"medium"}
            color="black"
            textAlign={"center"}
          >
            {t("headerTitle")}
            {state.ticket?.node !== null || state.ticket.node !== undefined ? (
              <Typography
                variant="h5"
                component="span"
                fontWeight={600}
                color="black"
                fontFamily={"Poppins"}
                textAlign={"center"}
              >
                {" " +
                  state.ticket?.node?.make +
                  " " +
                  state.ticket?.node?.model +
                  " " +
                  state.ticket?.node?.motortype +
                  " Series"}
              </Typography>
            ) : null}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Header;
