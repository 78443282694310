import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ActionCard from "../library-components/ActionCard/ActionCard";
import { CardStyleType } from "../library-components/ActionCard/PropTypesInterface/ActionCardPropTypeInterface";
import BasketContent from "./BasketSubComponents/BasketContent";
import TrustBoxMini from "./TrustBoxMini/TrustBoxMini";
import { useStylesDesktopSummary } from "./Styles/SummaryStyles";
import { useTranslation } from "react-i18next";
import ThumbsUp from "../../assets/illustrations/ThumbsUp.svg";
import {
  LinkButton,
  SimpleButton,
} from "../library-components/Buttons/Buttons";
import InfoDialog from "../library-components/Dialogs/InfoDialog/InfoDialog";
type BasketStylePropType = {
  isCheckout: boolean;
};
const Summary: React.FC = () => {
  const props: BasketStylePropType = {
    isCheckout: window.location.pathname.includes("checkout"),
  };

  const classes = useStylesDesktopSummary(props);
  const { t } = useTranslation();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const style: CardStyleType = {
    header: {
      padding: "4% 2% 0px 3.5%",
      margin: 0,
    },
    content: {
      paddingLeft: window.location.href.includes("checkout") ? "4%" : "",
      marginTop: window.location.href.includes("checkout") ? "-2.5%" : "",
    },
    container: {
      padding: "0.5rem",
      width: !window.location.href.includes("checkout") ? "27vw" : "45vw",
      display: window.location.href.includes("checkout") ? "flex" : "",
      flexDirection: window.location.href.includes("checkout") ? "column" : "",
      justifyContent: window.location.href.includes("checkout")
        ? "flex-start"
        : "",
      alignItems: window.location.href.includes("checkout") ? "flex-start" : "",
    },
    boxHeight: {
      width: !window.location.href.includes("checkout") ? "27vw" : "45vw",
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    rootStyle: {
      position: "fixed",
    },
    boxWidth: !window.location.href.includes("checkout")
      ? {
          width: "100%",
        }
      : null,
    boxShadow: {
      boxShadow: "none",
    },
  };
  return (
    <Grid
      item
      container
      md={!window.location.href.includes("checkout") ? 4 : 12}
      className={classes.root}
    >
      <div className={classes.position}>
        <div className={classes.wrapper}>
          <ActionCard
            Header={null}
            Content={
              <div>
                <BasketContent />
              </div>
            }
            Actions={null}
            style={style}
          />
          <div className={classes.reviews}>
            {!window.location.href.includes("checkout") ? (
              <TrustBoxMini />
            ) : null}
          </div>
          {!window.location.href.includes("checkout") ? (
            <div className={classes.basketLinks}>
              <div>
                <div className={classes.grid}>
                  <img className={classes.thumbsUpImg} src={ThumbsUp} />
                  <Typography fontWeight={"bold"}>
                    {t("factoryWarranty")}
                  </Typography>
                </div>
                <div className={classes.grid}>
                  <img className={classes.thumbsUpImg} src={ThumbsUp} />
                  <Typography fontWeight={"bold"}>
                    {t("noHiddenFees")}
                  </Typography>
                </div>
                <div className={classes.grid}>
                  <img className={classes.thumbsUpImg} src={ThumbsUp} />
                  <Typography fontWeight={"bold"}>
                    {t("nationwideService")}
                  </Typography>
                </div>
              </div>
              <div className={classes.border}></div>
              <div className={classes.marginTop}>
                <div>
                  <LinkButton
                    classes={classes.quoteText}
                    onClick={() => setOpen1(true)}
                  >
                    Hvordan fungerer det?
                  </LinkButton>
                </div>
                <div>
                  <LinkButton
                    classes={classes.quoteText}
                    onClick={() => setOpen2(true)}
                  >
                    Om Omnicarservice
                  </LinkButton>
                </div>
                <div>
                  <LinkButton
                    classes={classes.quoteText}
                    onClick={() => setOpen3(true)}
                  >
                    FAQ
                  </LinkButton>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <InfoDialog
        open={open1}
        fullWidth={true}
        onClose={() => setOpen1(false)}
        title={t("thisIsHowItWorks")}
        content={
          <Grid>
            <Typography fontWeight={"bold"}>
              {t("howDoesItWorkHeader1")}
            </Typography>
            <Typography>{t("howDoesItWorkBody1")}</Typography>
            <Typography className={classes.marginTop}>
              {t("howDoesItWorkBody2")}
            </Typography>
            <Typography fontWeight={"bold"} className={classes.marginTop}>
              {t("howDoesItWorkHeader2")}
            </Typography>
            <Typography>{t("howDoesItWorkBody3")}</Typography>
            <Typography fontWeight={"bold"} className={classes.marginTop}>
              {t("howDoesItWorkHeader3")}
            </Typography>
            <Typography>{t("howDoesItWorkBody4")}</Typography>
            <Typography className={classes.marginTop}>
              {t("howDoesItWorkBody5")}
            </Typography>
          </Grid>
        }
        actions={
          <SimpleButton
            classes={classes.dialogCloseButton}
            onClick={() => setOpen1(false)}
          >
            <Typography className={classes.dialogCloseButtonText}>
              {t("closeButtonDialog")}
            </Typography>
          </SimpleButton>
        }
        actionsStyle={classes.dialogCloseButtonWrapper}
      ></InfoDialog>
      <InfoDialog
        open={open2}
        fullWidth={true}
        onClose={() => setOpen2(false)}
        title={t("omOmnicarserviceHeader")}
        content={
          <Grid>
            <Typography fontWeight={"bold"}>
              {t("omOmnicarserviceHeader1")}
            </Typography>
            <Typography>{t("omOmnicarserviceBody1")}</Typography>
            <Typography fontWeight={"bold"} className={classes.marginTop}>
              {t("omOmnicarserviceHeader2")}
            </Typography>
            <Typography>{t("omOmnicarserviceBody2")}</Typography>
            <Typography fontWeight={"bold"} className={classes.marginTop}>
              {t("omOmnicarserviceHeader3")}
            </Typography>
            <Typography>{t("omOmnicarserviceBody3")}</Typography>
            <Typography fontWeight={"bold"} className={classes.marginTop}>
              {t("omOmnicarserviceHeader4")}
            </Typography>
            <Typography>{t("omOmnicarserviceBody4")}</Typography>
          </Grid>
        }
        actions={
          <SimpleButton
            classes={classes.dialogCloseButton}
            onClick={() => setOpen2(false)}
          >
            <Typography className={classes.dialogCloseButtonText}>
              {t("closeButtonDialog")}
            </Typography>
          </SimpleButton>
        }
        actionsStyle={classes.dialogCloseButtonWrapper}
      ></InfoDialog>
      <InfoDialog
        open={open3}
        fullWidth={true}
        onClose={() => setOpen3(false)}
        title={"FAQ"}
        content={
          <Grid>
            <iframe
              src="https://omnicarservice.dk/faq/"
              frameBorder="0"
              sandbox="	allow-forms allow-pointer-lock allow-popups allow-scripts allow-top-navigation"
              className={classes.iframe}
            ></iframe>
          </Grid>
        }
        actions={
          <SimpleButton
            classes={classes.dialogCloseButton}
            onClick={() => setOpen3(false)}
          >
            <Typography className={classes.dialogCloseButtonText}>
              {t("closeButtonDialog")}
            </Typography>
          </SimpleButton>
        }
        actionsStyle={classes.dialogCloseButtonWrapperr}
        maxWidth={true}
      ></InfoDialog>
    </Grid>
  );
};

export default Summary;
