import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  root: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
  },
  rootHeader: {
    width: "100%",
    paddingTop: "15px",
  },
  wrapperHeader: {
    padding: 0,
    width: "90%",
  },
  rootContent: {
    flexDirection: "column",
  },
  boxContent: {
    flexDirection: "row",
    margin: "3% 0px 1% 0px",
    width: "90%",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
  buttonContent: {
    display: "flex-start !important",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  buttonContentWrapper: {
    flexDirection: "row",
    justifyContent: "flex-start !important",
    width: "86vw",
  },
  contentImageWrapper: {
    alignItems: "inherit !important",
    flexGrow: 1,
    flexDirection: "row",
  },
  imageContent: {
    height: 30,
    width: 30,
  },
  cardTextContent: {
    padding: 0,
    textTransform: "capitalize",
    flexGrow: 1,
    width: "50%",
  },
  cardTextContentTitle: {
    color: "black",
    textAlign: "start",
    fontSize: "11pt !important",
  },
  cardArrowContent: {
    display: "flex",
    alignItems: "inherit",
    justifyContent: "flex-end",
    flexGrow: 1,
    flexDirection: "row",
  },
});
