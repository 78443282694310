import { Grid} from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { updateTicketReturningCustomer } from "../../../../../../API/graphql-queries/Queries";
import { GlobalContext } from "../../../../../../context/GlobalContext";
import { ReturningCustomer } from "../../../../../../interfaces-and-unionTypes/Interfaces";
import { ReturningCustomerType } from "../../../../../../interfaces-and-unionTypes/UnionTypes";
import { SimpleButton } from "../../../../Buttons/Buttons";

type ReturningCustomerMobileButtonProps = {
  number: string;
  setNumber: (number: string) => void;
};

const Actions: FC<ReturningCustomerMobileButtonProps> = (props) => {
  const { t } = useTranslation();
  const { number, setNumber } = props;
  const [error, setError] = useState("");
  const { state, dispatch } = useContext(GlobalContext);

  useEffect(() => {
    dispatch({ type: "RETURNING_CUSTOMER_ERROR", payload: { error } });
  }, [error]);

  const history = useHistory();

  const [updateReturningCustomer, result] = useMutation<
    Partial<ReturningCustomer>
  >(updateTicketReturningCustomer, {
    onCompleted: (data) => {
        dispatch({
            type: "RETURNING_CUSTOMER",
            payload: data?.updateTicketReturningCustomer,
        })
        if(data?.updateTicketReturningCustomer?.node.fleet_manager_for?.discount_coupon) {
            dispatch({
                type: "COUPON_URL",
                payload: data?.updateTicketReturningCustomer?.node.fleet_manager_for?.discount_coupon,
            });
        }
    }
  });

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      // if (result?.data?.updateTicketReturningCustomer?.node === null) {
      //   alert(result?.data?.updateTicketReturningCustomer?.message);
      // } else {
      if (
        result?.data?.updateTicketReturningCustomer?.message ===
        "Session expired"
      ) {
        alert("Session expired");
        history.push("/");
      }
      // }
    }
  }, [result?.data]);

  const findCustomer = async (number: string) => {
    if (number.trim() == "") {
      setNumber("");
      history.push("/");
      return;
    }
    // TODO: input type numeric type text from mui
    if (number.startsWith("+")) {
      number = number.slice(1, number.length);
    }
    // number = "45" + number;
    updateReturningCustomer({
      variables: {
        ticket_id: state.returningCustomer?.node?.ticket_id || "",
        phone_number: number,
      },
    }).then((res) => {
      dispatch({
        type: "RETURNING_CUSTOMER",
        payload: res?.data?.updateTicketReturningCustomer,
      });
      validateNumber(res.data?.updateTicketReturningCustomer);
    });
  };

  const validateNumber = (customer?: ReturningCustomerType | any) => {
    if (customer?.node === null) {
      setError(t("phoneNumberErrorInvalid"));
      dispatch({ type: "RETURNING_CUSTOMER_ERROR", payload: { error } });
      setNumber("");
    } else {
      setNumber("");
      setError("");
    }
    history.push("/");
  };

  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "12vh",
        fontWeight: "bold",
        paddingBottom: "3vh",
      }}
    >
      <Grid
        item
        container
        md={12}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "4%",
        }}
      >
        <SimpleButton
          style={{
            backgroundColor: "#4BC0E1",
            color: "black",
            width: 155,
            height: 47,
            fontWeight: 600,
            textTransform: "capitalize",
          }}
          onClick={() => {
            findCustomer(number);
          }}
        >
          {t("continueButtonLabel")}
        </SimpleButton>
      </Grid>
    </Grid>
  );
};

export default Actions;
