export const calculateTotalPrice = ((service_details: any) => {
    let totalPrice = 0;
    let stringPrice = "";

    service_details.forEach((service: any) => {
        let servicePrice: string = service.sa_price.toString()

        const is3rdLastCharacterAPeriod = servicePrice.charAt(servicePrice.length - 3) === "."
        const is2rdLastCharacterAPeriod = servicePrice.charAt(servicePrice.length - 2) === "."
        if (is3rdLastCharacterAPeriod || is2rdLastCharacterAPeriod) {
            const priceAsArray = servicePrice.split(".");
            const trailingDecimals = priceAsArray.pop();

            const newPriceString = priceAsArray.join("");
            totalPrice += Number(newPriceString);

            stringPrice = totalPrice + "." + trailingDecimals;
        } else {
            servicePrice = servicePrice.replace(".", "");
            totalPrice += Number(servicePrice);
            stringPrice = totalPrice.toString()
        }
    });

    return stringPrice;
})

export const itemsInBasketWithMissingParts: (state: any) => any[] = ((state: any) => {
    const doesBasketHaveContent = state.basketData && state.basketData.service_details
    const itemsWithMissingParts: any[] = (doesBasketHaveContent && state.basketData.service_details.filter((item: any) => item.sa_missing === 1)) || [];

    return itemsWithMissingParts;
})

export const canTotalPriceBeCalculated = ((state: any) => {
    const canOneOrMoreItemsPriceCanNotBeCalculated = (state.basketData && itemsInBasketWithMissingParts(state).length > 0);

    const shouldItemsPriceBeDisplayed = !(canOneOrMoreItemsPriceCanNotBeCalculated || isAFullDiscountApplied(state));
    return shouldItemsPriceBeDisplayed;
})

export const isAFullDiscountApplied = ((state: any) => {
    const heavyCampaigns = ["fleggaardleasing0"]
    const isAFullDiscountApplied = heavyCampaigns.includes(state.basketData?.campaign_code);

    return isAFullDiscountApplied;
})
