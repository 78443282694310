import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  InputAdornment,
  useTheme,
  Typography,
  DialogContentText,
} from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { SimpleButton } from "../../Buttons/Buttons";
import { useDialogStylesReturningCustomer } from "../Styles/DialogStyle";
import { DialogProps } from "../Types/DialogTypes";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useMutation } from "@apollo/react-hooks";
import { updateTicketReturningCustomer } from "../../../../API/graphql-queries/Queries";
import { ReturningCustomer } from "../../../../interfaces-and-unionTypes/Interfaces";
import { ReturningCustomerType } from "../../../../interfaces-and-unionTypes/UnionTypes";
import { useTranslation } from "react-i18next";
import InputField from "../../InputFields/InputFields";
import { useHistory } from "react-router-dom";

const ReturningCustomerDialog: FC<DialogProps> = (props) => {
  const classes = useDialogStylesReturningCustomer();
  const { t } = useTranslation();
  const { state, dispatch } = useContext(GlobalContext);

  const [number, setNumber] = useState("");

  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    dispatch({ type: "RETURNING_CUSTOMER_ERROR", payload: { error } });
  }, [error]);

  useEffect(() => {
    setNumber(state.queryPhoneNumber?.phoneNumber);
  }, [state.queryPhoneNumber]);

  const [updateReturningCustomer, result] = useMutation<
    Partial<ReturningCustomer>
  >(updateTicketReturningCustomer);

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      // if (result?.data?.updateTicketReturningCustomer?.node === null) {
      //   alert(result?.data?.updateTicketReturningCustomer?.message);
      // } else {
      if (
        result?.data?.updateTicketReturningCustomer?.message ===
        "Session expired"
      ) {
        alert("Session expired");
        history.push("/");
        // }
      }
    }
  }, [result?.data]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { open, close } = props;

  const closeDialog = (data: any) => {
    close(data);
  };
  const findCustomer = async (number: string) => {
    if (number?.trim() == "" || number === null || number === undefined) {
      setNumber("");
      closeDialog(true);
      return;
    }
    if (number.startsWith("+")) {
      number = number.slice(1, number.length);
    }
    // number = "45" + number;
    updateReturningCustomer({
      variables: {
        ticket_id: state.returningCustomer?.node?.ticket_id || "",
        phone_number: number,
      },
    }).then((res) => {
      dispatch({
        type: "RETURNING_CUSTOMER",
        payload: res?.data?.updateTicketReturningCustomer,
      });
      validateNumber(res.data?.updateTicketReturningCustomer);
      if(res?.data?.updateTicketReturningCustomer?.node?.fleet_manager_for?.discount_coupon) {
          dispatch({
              type: "COUPON_URL",
              payload: res?.data?.updateTicketReturningCustomer?.node?.fleet_manager_for?.discount_coupon,
          });
      }
    });
  };

  const validateNumber = (customer?: ReturningCustomerType) => {
    if (customer?.node === null) {
      setError(t("phoneNumberErrorInvalid"));
      setNumber("");
      closeDialog(true);
    } else {
      setNumber("");
      setError("");
      closeDialog(true);
    }
  };

  return (
    <Dialog
      open={open}
      className={classes.root}
      onClose={closeDialog}
      fullWidth={fullScreen}
      maxWidth={"md"}
      sx={{
        ".MuiPaper-root": {
          height: "42vh",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "center",
          WebkitJustifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          WebkitAlignContent: "center",
          WebkitAlignItems: "center",
          padding: "3vh 0vh 3vh 0vh",
        }}
      >
        <Typography
          style={{
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          {t("dialogTitleB2bCustomer")}
        </Typography>
      </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0vh",
        }}
      >
        <InputField
          style={{ width: "30vw" }}
          required={false}
          label={t("customerNumberLabel")}
          variant="outlined"
          focused={true}
          value={number ?? ""}
          onChange={(e: InputEvent) => {
            const value = (e.target as HTMLInputElement).value;
            const phoneRegex = new RegExp("^[0-9]{0,8}$");
            if (phoneRegex.test(value)) {
              setNumber(value);
              setError("");
            }
            if (
              (e.target as HTMLInputElement).value.length <=
              (e.target as HTMLInputElement).value.length - 1
            )
              setError("");
          }}
          placeholder={t("inputCustomerNumberLabel")}
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          inputProps={{
            inputMode: "numeric",
            pattern: "^[0-9]{0,8}$",
            maxLength: 8,
          }}
        />
      </DialogContent>
      <DialogContent
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0vh 0vh 3vh 0vh",
        }}
      >
        <DialogContentText style={{ textAlign: "center", width: "39vw" }}>
          {t("dialogDescriptionB2bCustomer")}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // height: "12vh",
          fontWeight: "bold",
          // paddingBottom: "3vh",
          padding: "0vh 0vh 7vh 0vh",
        }}
      >
        <SimpleButton
          style={{
            backgroundColor: "#4BC0E1",
            color: "black",
            width: 155,
            height: 47,
            fontWeight: 600,
            textTransform: "capitalize",
          }}
          onClick={async () => {
            findCustomer(number);
          }}
        >
          {t("continueButtonLabel")}
        </SimpleButton>
      </DialogActions>
    </Dialog>
  );
};

export default ReturningCustomerDialog;
