import Paper from "@mui/material/Paper";
import React, {
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { isMobile } from "react-device-detect";
import TimeslotHeader from "./TimeslotHeader";
import TimeslotBody from "./TimeslotBody";
import { GlobalContext } from "../../../context/GlobalContext";
import { useLazyQuery } from "react-apollo";
import {
  plannerMatrics,
  contactDetailsScreen,
} from "../../../API/graphql-queries/Queries";
import { TimeslotData } from "../../../interfaces-and-unionTypes/UnionTypes";
import {
  localizeDateFull,
  normalizeDate,
  setToMonday,
} from "../../../Helpers/timeslotHelpers";
import { ContactDetailsScreenResponse } from "../../../interfaces-and-unionTypes/Interfaces";
import { useStylesTimeslot } from "./TimeslotStyles";
import ServiceLoaderDialog from "../../library-components/Dialogs/ServiceLoaderDialog";
import { useHistory } from "react-router-dom";

interface TimeslotProps {
  elevation: number;
  numberOfDays: number;
  date: Date;
  onSelectTimeslot: () => void;
  timeSlots: [];
  selectLimit: number;
  zipCode: string;
  city: string;
  address: string;
}

const TimeslotCalendar: FC<TimeslotProps> = ({
  elevation = 0,
  numberOfDays,
  date = new Date(),
  onSelectTimeslot,
  timeSlots = [],
  selectLimit = 1,
  zipCode,
  city,
  address,
}) => {
  const [currentWeek, setCurrentWeek] = useState<TimeslotData[]>([]);
  const [calendarDays, setCalendarDays] = useState(0);
  const [mappedCurrentWeek, setMappedCurrentWeek] = useState<
    Map<string, TimeslotData>
  >(new Map());
  const { state, dispatch } = useContext(GlobalContext);
  const classes = useStylesTimeslot();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [loadTimeslots, result] = useLazyQuery<Partial<any>>(plannerMatrics, {
    onCompleted: (data) => {
      setCurrentWeek(data?.plannerMatrics?.node);
      if (flag === true) {
        setHeaderDay(normalizeDate(data?.plannerMatrics?.node[0]?.date));
        setTodayDate(normalizeDate(data?.plannerMatrics?.node[0]?.date));
        setFlag(false);
      }
      setCurrentDay(normalizeDate(data?.plannerMatrics?.node[0]?.date));
      setLoading(false);
    },
    fetchPolicy: "no-cache",
  });
  const [getCalendarDays, resultCalendarDays] = useLazyQuery<
    Partial<ContactDetailsScreenResponse>
  >(contactDetailsScreen, {
    onCompleted: (data) => {
      setCalendarDays(
        parseInt(data?.contactDetailsScreen?.node?.calendar_days || "")
      );
    },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (resultCalendarDays?.data === null) {
      alert(resultCalendarDays?.error?.message);
    } else {
      if (resultCalendarDays?.data?.contactDetailsScreen?.node === null) {
        alert(resultCalendarDays?.data?.contactDetailsScreen?.message);
      }
      if (
        resultCalendarDays?.data?.contactDetailsScreen?.message ===
        "Session expired"
      ) {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [resultCalendarDays?.data]);

  // useEffect(() => {
  //   if (result?.data === null) {
  //     alert(result?.error?.message);
  //   } else {
  //     if (result?.data?.plannerMatrics?.node === null) {
  //       alert(result?.data?.plannerMatrics?.message);
  //     }
  //     if (result?.data?.plannerMatrics?.message === "Session expired") {
  //       alert("Session expired");
  //       history.push("/");
  //     }
  //   }
  // }, [result?.data]);
  const userZipCode = state.ticket?.node?.zip_code
    ? state.ticket?.node?.zip_code
    : "";
  const userCity = state.ticket?.node.city ? state.ticket?.node.city : "";
  const userAddress =
    state?.contactDetails?.node?.address + "," + userZipCode + "," + userCity;

  useEffect(() => {
    loadTimeslots({
      variables: {
        calendar_date: localizeDateFull(currentDay),
        mobile_web: isMobile ? "M" : "W",
        ticket_id: state.ticket?.node?.ticket_id,
        zip_code: state?.ticket?.node?.zip_code,
        user_address: state?.contactDetails?.node?.address
          ? userAddress
          : userZipCode + "," + userCity,
      },
    });
    getCalendarDays({
      variables: {
        ticket_id: state.ticket?.node.ticket_id,
      },
    });
    if (result?.data?.data?.plannerMatrics?.node === undefined) {
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    if (isMountedAddress.current) {
      loadTimeslots({
        variables: {
          calendar_date: localizeDateFull(currentDay),
          mobile_web: isMobile ? "M" : "W",
          ticket_id: state.ticket?.node?.ticket_id,
          zip_code: state?.zipCodeChanged,
          user_address: address + "," + zipCode + "," + city,
        },
      });
      if (result?.data?.data?.plannerMatrics?.node !== undefined) {
        setCurrentWeek(result?.data?.plannerMatrics?.node);
      } else {
        setLoading(true);
      }
    } else {
      isMountedAddress.current = true;
    }
  }, [state?.zipCodeChanged]);

  const [currentDay, setCurrentDay] = useState(setToMonday(new Date()));
  const [todayDate, setTodayDate] = useState(new Date());
  const [headerDay, setHeaderDay] = useState(setToMonday(date));
  const isMounted = useRef(false);
  const isMountedAddress = useRef(false);
  const isMountedd = useRef(false);
  const [flag, setFlag] = useState(true);
  const getPreviousWeek = () => {
    if (zipCode.trim() !== "" && city.trim() !== "") {
      if (isMobile && todayDate < currentDay) {
        setCurrentDay(new Date(currentDay.setDate(currentDay.getDate() - 3)));
        setHeaderDay(new Date(currentDay.setDate(currentDay.getDate())));
      } else if (!isMobile && todayDate < currentDay) {
        setCurrentDay(new Date(currentDay.setDate(currentDay.getDate() - 7)));
        setHeaderDay(new Date(currentDay.setDate(currentDay.getDate())));
      }
    }
  };

  const getNextWeek = () => {
    if (zipCode.trim() !== "" && city.trim() !== "") {
      if (
        isMobile &&
        new Date(
          todayDate.getTime() + (calendarDays - 3) * 24 * 60 * 60 * 1000
        ) >= currentDay
      ) {
        setCurrentDay(new Date(currentDay.setDate(currentDay.getDate() + 3)));
        setHeaderDay(new Date(currentDay.setDate(currentDay.getDate())));
      } else if (
        !isMobile &&
        new Date(
          todayDate.getTime() + (calendarDays - 7) * 24 * 60 * 60 * 1000
        ) >= currentDay
      ) {
        setCurrentDay(new Date(currentDay.setDate(currentDay.getDate() + 7)));
        setHeaderDay(new Date(currentDay.setDate(currentDay.getDate())));
      }
    }
  };
  useEffect(() => {
    if (isMounted.current) {
      isMobile
        ? setCurrentDay(state?.datePickerChoice || new Date())
        : setCurrentDay(setToMonday(state?.datePickerChoice || new Date()));
      setHeaderDay(state?.datePickerChoice || new Date());
    } else {
      isMounted.current = true;
    }
  }, [state?.datePickerChoice]);

  useEffect(() => {
    if (isMountedd.current) {
      if (
        currentDay.getTime() ===
        normalizeDate(result?.data?.plannerMatrics?.node[0]?.date).getTime()
      ) {
        setLoading(false);
      } else {
        loadTimeslots({
          variables: {
            calendar_date: localizeDateFull(currentDay),
            mobile_web: isMobile ? "M" : "W",
            ticket_id: state.ticket?.node?.ticket_id,
            zip_code: zipCode,
            user_address: address + "," + zipCode + "," + city,
          },
        });
        if (result?.data?.data?.plannerMatrics?.node !== undefined) {
          setCurrentWeek(result?.data?.plannerMatrics?.node);
        } else {
          setLoading(true);
        }
      }
    } else {
      isMountedd.current = true;
    }
  }, [currentDay]);

  const mapCurrentWeek = () => {
    const daysByID = new Map<string, TimeslotData>(
      currentWeek.map((obj) => [obj.date, obj])
    );
    return daysByID;
  };

  useEffect(() => {
    setMappedCurrentWeek(mapCurrentWeek());
  }, [currentWeek]);

  return (
    <Paper elevation={elevation} className={classes.paperRoot}>
      <TimeslotHeader
        headerDay={headerDay}
        getPreviousWeek={getPreviousWeek}
        getNextWeek={getNextWeek}
        fixedDate={false}
        calendarDays={calendarDays}
      />

      <TimeslotBody currentWeek={mappedCurrentWeek} selectLimit={selectLimit} />
      <ServiceLoaderDialog
        open={loading}
        close={() => {
          return;
        }}
      />
    </Paper>
  );
};

export default TimeslotCalendar;
