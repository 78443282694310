import React, { FC, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Grid, Typography, Box, Button } from "@mui/material";
import { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import {
  TimeslotData,
  TimeslotType,
} from "../../../interfaces-and-unionTypes/UnionTypes";
import {
  localizeDateFull,
  localizeDateShort,
  normalizeDate,
  toWeekDayShort,
  splitWeekDay,
} from "../../../Helpers/timeslotHelpers";
import { useStylesTimeslot } from "./TimeslotStyles";

interface TimeslotBodyProps {
  currentWeek: Map<string, TimeslotData>;
  selectLimit: number;
}

interface TimeslotCbFn {
  (value: TimeslotData, key: string): { key: string; value: TimeslotData };
}

const TimeslotBody: FC<TimeslotBodyProps> = ({ currentWeek, selectLimit }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const classes = useStylesTimeslot();

  const selectTimeSlot = (
    day: number,
    thisMonth: number,
    year: number,
    slot: TimeslotType,
    event: any
  ): void => {
    const month = thisMonth + 1;

    const element = document.getElementById(event.target.id);
    const selected = checkIfSelected(slot);

    if (state?.selectedTimeslots?.length < selectLimit) {
      if (!selected) {
        dispatch({
          type: "SELECT_TIMESLOT",
          payload: slot?.id,
        });
        element?.classList.add(classes.selectedBtn);
        return;
      }

      const indexOfObject = state?.selectedTimeslots.findIndex((object) => {
        return object === slot.id;
      });
      const elToDelete = state?.selectedTimeslots.splice(indexOfObject, 1)[0];
      const el = document.getElementById(elToDelete);
      dispatch({ type: "UNSELECT_TIMESLOT", payload: elToDelete });
      el?.classList.remove(classes.selectedBtn);
      return;
    }

    if (!selected) {
      const elToDelete = state.selectedTimeslots.shift();
      const el = document.getElementById(elToDelete as string);
      dispatch({ type: "UNSELECT_TIMESLOT", payload: elToDelete });
      el?.classList.remove(classes.selectedBtn);
      dispatch({
        type: "SELECT_TIMESLOT",
        payload: slot?.id,
      });
      element?.classList.add(classes.selectedBtn);
    } else {
      const indexOfObject = state?.selectedTimeslots.findIndex((object) => {
        return object === slot.id;
      });
      const elToDelete = state?.selectedTimeslots.splice(indexOfObject, 1)[0];
      const el = document.getElementById(elToDelete);
      dispatch({ type: "UNSELECT_TIMESLOT", payload: elToDelete });
      el?.classList.remove(classes.selectedBtn);
    }
  };
  const checkIfSelected = (timeslot: TimeslotType) => {
    const exists = state?.selectedTimeslots.some((time) => {
      return time === timeslot?.id;
    });
    return exists;
  };
  useEffect(() => {
    dispatch({ type: "EMPTY_SELECTED_TIMESLOTS", payload: [] });
  }, []);
  useEffect(() => {
    mapItems(currentWeek).map((item) => {
      item?.value?.slots.forEach((timeslot) => {
        const el = document.getElementById(timeslot.id);
        el?.classList.remove(classes.selectedBtn);
      });
    });
  }, [state?.zipCodeChanged]);
  useEffect(() => {
    if (state?.selectedTimeslots.length === 0) {
      mapItems(currentWeek).map((item) => {
        item?.value?.slots.forEach((timeslot) => {
          const el = document.getElementById(timeslot.id);
          el?.classList.remove(classes.selectedBtn);
        });
      });
    }
  }, [state?.selectedTimeslots]);
  useEffect(() => {
    state?.selectedTimeslots.forEach((item) => {
      const el = document.getElementById(item);
      el?.classList.add(classes.selectedBtn);
    });
  }, [currentWeek]);
  const mapItems = (
    map: Map<string, TimeslotData>,
    callbackfn?: TimeslotCbFn
  ) => {
    const agg = [];
    for (const [key, value] of map) {
      agg.push(callbackfn ? callbackfn(value, key) : { value, key });
    }
    return agg;
  };
  return (
    <Grid container display="flex" flexWrap="nowrap">
      {mapItems(currentWeek).map(({ key, value }) => (
        <Grid
          item
          xs={2}
          key={key}
          style={{
            border: "0.5pt solid #F0F0F0",
            borderRadius: 0,
            maxWidth: isMobile ? "26.5vw" : "5.75vw",
            width: isMobile ? "" : "5.75vw",
          }}
        >
          <Box
            style={{
              backgroundColor: "#E1F8FF",
              height: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              color={"#4BC0E2"}
              fontSize={12}
              fontFamily={"Poppins, Poppins-Regular"}
            >
              {splitWeekDay(value?.date_dsc)[0]}
            </Typography>
            <Typography fontSize={14} fontFamily={"Poppins, Poppins-Bold"}>
              {splitWeekDay(value?.date_dsc)[1]}
            </Typography>
          </Box>
          <Grid
            container
            display="flex"
            flexDirection="column"
            width={isMobile ? "26.5vw" : "100%"}
          >
            {value?.slots?.map((slot, index) => (
              <Button
                key={index}
                id={slot.id}
                onClick={(e) => {
                  selectTimeSlot(
                    normalizeDate(value.date).getDate(),
                    normalizeDate(value.date).getMonth(),
                    normalizeDate(value.date).getFullYear(),
                    slot,
                    e
                  );
                }}
                style={{
                  borderTop: "0.5pt solid #F0F0F0",
                  borderBottom: "0.5pt solid #F0F0F0",
                  borderRadius: 0,
                  fontSize: 14,
                }}
                disabled={!slot.enabled}
              >
                {slot.startTime + "-" + slot.endTime}
              </Button>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default TimeslotBody;
