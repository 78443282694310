import { Grid, Typography } from "@mui/material";
import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../../../context/GlobalContext";
import { useStyles } from "../Style/Styles";

const Header: FC = () => {
  const { t } = useTranslation();

  const { state } = useContext(GlobalContext);

  const classes = useStyles();

  return (
    <Grid
      item
      container
      md={12}
      className={`${classes.root} ${classes.rootHeader}`}
    >
      <div className={`${classes.root} ${classes.wrapperHeader}`}>
        <Typography
          variant="h5"
          component="div"
          fontFamily={"Poppins"}
          fontWeight={"medium"}
          color="black"
          textAlign={"center"}
        >
          {t("headerTitle") + " "}
          {state.ticket?.node !== null || state.ticket.node !== undefined ? (
            <Typography
              variant="h5"
              component="span"
              fontWeight={600}
              color="black"
              fontFamily={"Poppins"}
            >
              {state.ticket?.node?.make +
                " " +
                state.ticket?.node?.model +
                " " +
                state.ticket?.node?.motortype +
                " Series"}
            </Typography>
          ) : null}
        </Typography>
      </div>
    </Grid>
  );
};

export default Header;
