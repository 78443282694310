import { makeStyles } from "@mui/styles";
import { isMobile } from "react-device-detect";

export const useStylesServicing = makeStyles({
  root: {
    display: "flex",
    justifyContent: !isMobile ? "" : "center",
    alignItems: "center",
    marginTop: !isMobile ? "" : "20px",
  },
  box: {
    padding: "3vh 0 0 0",
  },
});
export const useStylesDesktopServicingAddRemoveButtons = makeStyles({
  guaranteeServiceRoot: {
    display: "flex!important",
    justifyContent: "space-between!important",
    alignItems: "center!important",
    width: "100%!important",
  },
  guaranteeServiceInputFieldWrapper: {
    width: "70%!important",
  },
  guaranteeInputField: {
    height: "2.3vh",
  },
  guaranteeInputButtonWrapper: {
    width: "25%!important",
    display: "flex!important",
    justifyContent: "center!important",
  },
  guaranteeInputButton: {
    background: "#4BC0E1",
    color: "black",
    textTransform: "capitalize !important" as "capitalize",
    width: "96px",
    height: "44px",
    fontWeight: "bold!important",
    fontSize: 14,
  },
  addButton: {
    background: "#4BC0E1",
    color: "white !important",
    textTransform: "capitalize !important" as "capitalize",
    width: "4.8vw",
    height: "4vh",
    fontWeight: "bold !important",
    fontSize: 14,
  },
  removeButton: {
    background: "rgb(247 44 44 / 64%) !important",
    color: "#ffffff !important",
    textTransform: "capitalize !important" as "capitalize",
    width: "4.8vw",
    height: "4vh",
    fontWeight: "bold !important",
    fontSize: 14,
  },
});
export const useStylesServicingCartOptions = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "0px 0% 0px 3%",
  },
  rootMobile: {
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
    padding: "3%",
    flexDirection: "column !important" as "column",
  },
  cardWrapper: {
    background: !isMobile
      ? "#E1F8FF 0% 0% no-repeat padding-box"
      : "#FFFFFF 0% 0% no-repeat padding-box",
    display: "flex",
    paddingLeft: "2%",
    paddingRight: "2%",
    paddingTop: "2%",
    width: !isMobile ? "26vw" : "100%",
    height: "19.3vh",
    flexDirection: "column !important" as "column",
    margin: "2% !important",
    boxShadow: !isMobile ? "" : "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: !isMobile ? "" : "5%",
  },
  infoButton: {
    margin: "0px !important",
    padding: "0px !important",
  },
  infoButtonIcon: {
    color: "#4BC0E1",
  },
  cardText: {
    height: !isMobile ? "9vh" : "6vh",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: !isMobile ? "flex-start" : "center",
    paddingTop: !isMobile ? "2%" : "",
    lineHeight: !isMobile ? "1.2" : "",
  },
  cardPrice: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardPriceTextWeight: {
    fontWeight: "700 !important",
  },
});
export const useStylesDesktopServicingTables = makeStyles({
  root: {
    padding: "44px 3.5% 10% 3.5%",
  },
  table: {
    minWidth: "100%",
    tableLayout: "fixed",
    borderCollapse: "collapse",
  },
  tableHeadCells: {
    fontFamily: "Poppins !important",
    fontWeight: "bold !important",
    fontSize: !isMobile ? "16px !important" : "12px !important",
  },
  tableRowLastChildBorder: {
    "&:last-child td, &:last-child th": {
      border: "none",
    },
  },
  checklistLinkSize: {
    fontSize: !isMobile ? "12px" : "10px",
  },
  doneIcon: {
    color: "#34D389",
  },
  closeIcon: {
    color: "#DF5252",
  },
  note: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    background: "#E1F8FF",
    padding: "1%",
    marginTop: "2% !important",
  },
  checklistCloseButton: {
    textTransform: "capitalize",
    backgroundColor: "#4BC0E1",
    color: "black",
    cursor: "pointer",
    width: "119px",
    height: "47px",
  },
  checklistCloseButtonText: {
    fontSize: !isMobile ? "18px !important" : "15px !important",
    fontWeight: "bold !important",
  },
  checklistCloseButtonWrapper: {
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    padding: "2% 0px 5% 0px !important",
  },
});
export const useStylesDesktopServicingChecklist = makeStyles({
  root: {
    paddingTop: "4%",
  },
  tableContainer: {
    overflowX: "hidden !important" as "hidden",
  },
  table: {
    minWidth: "100%",
    boxShadow: "1px 5px",
    tableLayout: "fixed",
  },
  tableHeadCells: {
    padding: "1px !important",
  },
  noteWrapper: {
    padding: "2%",
    backgroundColor: "#E1F8FF",
  },
});
