import { Grid } from "@mui/material";
import React, { FC, useContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  computeCampaign,
  contactDetailsScreen,
} from "../../../API/graphql-queries/Queries";
import { GlobalContext } from "../../../context/GlobalContext";
import {
  ComputeServiceDataResponse,
  ContactDetailsScreenResponse,
} from "../../../interfaces-and-unionTypes/Interfaces";
import {
  LinkButton,
  SimpleButton,
} from "../../library-components/Buttons/Buttons";
import { useStylesMobileBasketFloatingContinueButton } from "../Styles/SummaryStyles";
import CampaignCodeDialog from "../../library-components/Dialogs/CampaignCodeDialog";

const ContinueButtonMobile: FC = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(GlobalContext);

  const { t } = useTranslation();
  const query = useLocation();

  const classes = useStylesMobileBasketFloatingContinueButton();

  const heavyCampaigns = ["fleggaardleasing0"]
  const [openCampaignCodeModal, setOpenCampaignCodeModal] = useState(false);

  const [fillContactDetails, resultFill] = useLazyQuery<
    Partial<ContactDetailsScreenResponse>
  >(contactDetailsScreen, {
    onCompleted: (data) => {
      dispatch({
        type: "CONTACT_DETAILS_RESPONSE",
        payload: data.contactDetailsScreen,
      });
      history.push(`/services/checkout${query.search && query.search}`);
    },
  });
  useEffect(() => {
    if (resultFill?.data === null) {
      alert(resultFill?.error?.message);
    } else {
      if (resultFill?.data?.contactDetailsScreen?.node === null) {
        alert(resultFill?.data?.contactDetailsScreen?.message);
      }
      if (
        resultFill?.data?.contactDetailsScreen?.message === "Session expired"
      ) {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [resultFill?.data]);
  const [computedCampaign, result] = useLazyQuery<
    Partial<ComputeServiceDataResponse>
  >(computeCampaign, {
    onCompleted: (data) =>
      dispatch({
        type: "ADD_TO_BASKET",
        payload: data.computeCampaign?.node,
      }),
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (result?.data === null) {
      alert(result?.error?.message);
    } else {
      if (result?.data?.computeCampaign?.node === null) {
        alert(result?.data?.computeCampaign?.message);
      }
      if (result?.data?.computeCampaign?.message === "Session expired") {
        alert("Session expired");
        history.push("/");
      }
    }
  }, [result?.data]);

  return (
    <Grid item container md={12} className={classes.floatingContinueButtonRoot}>
      {!window.location.href.includes("checkout") ? (
        <Grid
          item
          container
          md={12}
          className={classes.floatingContinueButtonWrapper}
        >
          <Grid
            item
            container
            md={12}
            className={classes.floatingContinueButtonPosition}
          >
            <SimpleButton
              onClick={() => {
                fillContactDetails({
                  variables: {
                    ticket_id: state.ticket?.node.ticket_id,
                  },
                });
              }}
              classes={classes.floatingContinueButton}
            >
              {t("continueButton")}
            </SimpleButton>
            {!heavyCampaigns.includes(state.basketData?.campaign_code) &&
              <LinkButton
                style={{
                  fontWeight: "normal",
                  color: "#3265F3",
                  textDecorationColor: "#3265F3",
                  fontSize: 15,
                  marginTop: "20px",
                  marginBottom: "15px",
                }}
                onClick={() => setOpenCampaignCodeModal(true)}
              >
                {t("campaignCodeLink")}
              </LinkButton>}
          </Grid>
        </Grid>
      ) : null}
      <CampaignCodeDialog
        open={openCampaignCodeModal}
        close={() => setOpenCampaignCodeModal(false)}
        save={computedCampaign}
      />
    </Grid>
  );
};

export default ContinueButtonMobile;
