/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { LinkButton } from "../../library-components/Buttons/Buttons";
import { GlobalContext } from "../../../context/GlobalContext";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import InfoDialog from "../../library-components/Dialogs/InfoDialog/InfoDialog";
import ContinueButtonMobile from './ContinueButtonMobile'
import { useStylesMobileBasket } from "../Styles/SummaryStyles";
import { calculateTotalPrice } from "../../../services/price.service";

const BasketMobile: React.FC = () => {
  const { state } = useContext(GlobalContext);

  const { t } = useTranslation();

  const heavyCampaigns = ["fleggaardleasing0"]
  const history = useHistory();
  const classes = useStylesMobileBasket();

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogMin, setOpenDialogMin] = useState(false);


  const dialogParagraphs = [
    { 0: t("whatsThisDialogFirstParagraph") },
    { 1: t("whatsThisDialogSecondParagraph") },
    { 2: t("whatsThisDialogThirdParagraph") },
  ];

  return (
    <>
      {!window.location.pathname.includes("checkout") &&
        state.basketData &&
        state.basketData?.service_details &&
        state.basketData?.service_details?.length >= 1 ? (
        <Grid container item md={12} className={classes.root}>
          {
            <div className={classes.wrapper}>
              {state.basketData !== null || state.basketData !== undefined ? (
                <div>
                  <div>
                    <div className={classes.itemsWrapper}>
                      {!heavyCampaigns.includes(state.basketData?.campaign_code) ? <>
                        <Typography className={classes.priceText}>
                          {state.basketData?.total_price?.trim() !== undefined
                            ? calculateTotalPrice(state.basketData?.service_details) + " DKK"
                            : "0 DKK"}
                        </Typography>
                        {parseInt(
                          state.basketData?.total_price
                            ?.toString()
                            .replace(".", "")
                        ) < parseInt(state.basketData?.min_price?.replace(".", "")) && state.basketData?.root_missing !== 1 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                              fontWeight: "bold",
                              padding: "2% 0px 3% 0px",
                              width: "100%",
                              paddingRight: "7%",
                            }}
                          >
                            <LinkButton
                              style={{
                                fontWeight: "normal",
                                color: "rgb(247 44 44 / 1.64)",
                                textDecorationColor: "rgb(247 44 44 / 1.64)",
                                fontSize: "9.5pt",
                              }}
                              onClick={() => setOpenDialogMin(true)}
                            >
                              {t("basketMinPrice")}
                            </LinkButton>
                          </div>
                        ) : state.basketData?.root_missing === 1 ? (
                          <div className={classes.missingItem}>
                            <LinkButton
                              classes={classes.link}
                              onClick={() => setOpenDialog(true)}
                            >
                              {t("basketSomethingMissing")}
                            </LinkButton>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                              fontWeight: "bold",
                              padding: "2% 0px 3% 0px",
                              width: "50%",
                              paddingRight: "7%",
                            }}
                          >
                            <LinkButton
                              style={{
                                fontWeight: "normal",
                                color: "#3265F3",
                                textDecorationColor: "#3265F3",
                                fontSize: "12pt",
                              }}
                              onClick={() => history.push("/assistance")}
                            >
                              {t("needHelpLink")}
                            </LinkButton>
                          </div>
                        )}
                      </>
                        : <>

                          <div className={classes.itemsWrapper}>
                            <span>
                              <Typography className={classes.headerTitle}>
                              Fleggaard Leasing
                              </Typography>
                            </span>
                          </div>
                        </>}
                    </div>
                  </div>


                </div>
              ) : (
                <></>
              )}

              <ContinueButtonMobile />
            </div>
          }
          <InfoDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            title={t("whatsThisDialogTitle")}
            content={
              <>
                <div className={classes.whatsThisDialog}>
                  {dialogParagraphs.map((item: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        paddingTop: index === 0 ? "3%" : "5%",
                      }}
                    >
                      <Typography className={classes.whatsThisDialogText}>
                        {item[index]}
                      </Typography>
                    </div>
                  ))}
                </div>
              </>
            }
          />
          <InfoDialog
            open={openDialogMin}
            onClose={() => setOpenDialogMin(false)}
            title={t("minPriceDialogTitle")}
            content={
              <>
                <div className={classes.whatsThisDialog}>
                  <div className={classes.whatsThisDialogPadding}>
                    <Typography className={classes.whatsThisDialogText}>
                      {`Minimumskøb på ${parseInt(state.basketData?.min_price?.replace(".", ""))} DKK for online booking er ikke opnået. Fortsæt venligst din booking og vi kontakter dig indenfor 24 timer på hverdage.`}
                    </Typography>
                  </div>
                </div>
              </>
            }
          />
        </Grid>
      ) : null}
    </>
  );
};

export default BasketMobile;
