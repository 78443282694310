import { Grid, Link } from "@mui/material";
import React, { FC } from "react";
import ProgressTrackDesktop from "./ProgressTrackDesktop";
import { omnicarLogo, useStylesDesktop } from "./NavbarStyle";

const NavbarDesktop: FC = () => {
  const classes = useStylesDesktop();

  return (
    <Grid item container md={12} className={classes.wrapper}>
      <Grid item md={3} className={classes.imageWrapper}>
        <a href="https://omnicarservice.dk/" className={classes.linkNavbar}>
          <img src={omnicarLogo} className={classes.logo} />
        </a>
      </Grid>
      <Grid item md={9} className={classes.trackerWrapper}>
        <ProgressTrackDesktop />
      </Grid>
    </Grid>
  );
};

export default NavbarDesktop;
